port module Main exposing (..)

import Browser
import Browser.Events
import Browser.Navigation as Navigation
import Dict exposing (Dict)
import Html exposing (Html, br, button, div, input, text)
import Html.Attributes exposing (class, classList, disabled, value)
import Html.Events exposing (keyCode, on, onClick, onInput)
import Http
import Json.Decode as Decode
import Json.Encode as Encode
import Set exposing (Set)
import Task
import Time
import Tuple exposing (first)
import Url
import Url.Parser exposing ((</>))


type alias JsonValue =
    Encode.Value



-- TODO list
-- [x] Read room id from location header in publish response and set url and online in the state
-- [x] When receiving a published response, pushUrl, and set online
-- [x] If we are in a room, and it is not readonly, perform patchRequest instead of localStorage request
-- [x] init: If in a room (parse url room id), set modelIsLoading and online
-- [x] "Join room" button, to go online for a specific room (pushUrl on successful response)
-- [x] Perform getRoom request in `init` if we have a room id
-- [x] If we are in a room, and it is readonly, perform periodic fetches of the room (should not pushUrl on response)
-- [x] Dedicated error text element for all errors
-- [x] Only periodically fetch when tab is focused
-- [x] Stop periodic fetches entirely after an hour of activity, forcing the user to refresh the page to restart the periodic fetches
-- [x] Change data format to be arrays instead of objects, will save ~50% data size
-- [x] React to changing url:
--      "/" load state from local storage and set as offline
--      "/:roomId" do initial get room
-- [ ] Show loader when loading the initial state, and small loader when joining room
-- [ ] If stored state is empty, show "Organize competition OR Join Room"


main : Program JsonValue Model Msg
main =
    Browser.application
        { init = init
        , onUrlRequest = UrlRequest
        , onUrlChange = UrlChange
        , view = view
        , update = update
        , subscriptions = subscriptions
        }


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch <|
        Browser.Events.onVisibilityChange VisibilityChanged
            :: receiveFromLocalStorage GotFromLocalStorage
            :: Time.every (1000 * 60 * 5) GotCurrentTime
            :: (if model.readOnly && model.hasFocus && model.shouldRunPeriodicFetches then
                    [ Time.every 1000 (\_ -> PeriodicGetRoom) ]

                else
                    []
               )


port sendToLocalStorage : JsonValue -> Cmd msg


port requestFromLocalStorage : () -> Cmd msg


port receiveFromLocalStorage : (JsonValue -> msg) -> Sub msg


encodeEvent : Event -> JsonValue
encodeEvent event =
    case event of
        PersonAdded { name } ->
            Encode.list (\a -> a) [ Encode.int 0, Encode.string name ]

        PersonDisabled { id } ->
            Encode.list (\a -> a) [ Encode.int 1, Encode.int id ]

        PersonEnabled { id } ->
            Encode.list (\a -> a) [ Encode.int 2, Encode.int id ]

        ResultRegistered { winner, loser } ->
            Encode.list (\a -> a) [ Encode.int 3, Encode.int winner, Encode.int loser ]


encodeEvents : List Event -> JsonValue
encodeEvents events =
    Encode.list encodeEvent events


eventDecoder : Decode.Decoder Event
eventDecoder =
    Decode.index 0 Decode.int |> Decode.andThen decodeEvent


decodeEvent : Int -> Decode.Decoder Event
decodeEvent eventType =
    case eventType of
        0 ->
            Decode.map (\name -> PersonAdded { name = name })
                (Decode.index 1 Decode.string)

        1 ->
            Decode.map (\id -> PersonDisabled { id = id })
                (Decode.index 1 Decode.int)

        2 ->
            Decode.map (\id -> PersonEnabled { id = id })
                (Decode.index 1 Decode.int)

        3 ->
            Decode.map2 (\winner -> \loser -> ResultRegistered { winner = winner, loser = loser })
                (Decode.index 1 Decode.int)
                (Decode.index 2 Decode.int)

        _ ->
            Decode.fail "Invalid event"


eventsDecoder : Decode.Decoder (List Event)
eventsDecoder =
    Decode.oneOf [ Decode.list eventDecoder, Decode.null [] ]


decodeEvents : JsonValue -> List Event
decodeEvents json =
    case Decode.decodeValue eventsDecoder json of
        Ok result ->
            result

        Err _ ->
            []


type alias Name =
    String


type alias Id =
    Int


type Event
    = PersonAdded { name : Name }
    | PersonDisabled { id : Id }
    | PersonEnabled { id : Id }
    | ResultRegistered { winner : Id, loser : Id }


type alias MatchIndex =
    Int


type alias PersonSummary =
    { id : Id
    , name : Name
    , wins : Int
    , losses : Int
    , latest : MatchIndex
    , met : Set Id
    , disabled : Bool
    }


newPersonSummary : Id -> String -> PersonSummary
newPersonSummary id name =
    { id = id
    , name = name
    , wins = 0
    , losses = 0
    , latest = 0
    , met = Set.empty
    , disabled = False
    }


addWin : MatchIndex -> Id -> PersonSummary -> PersonSummary
addWin matchIndex against p =
    { p | wins = p.wins + 1, latest = matchIndex, met = Set.insert against p.met }


addLoss : MatchIndex -> Id -> PersonSummary -> PersonSummary
addLoss matchIndex against p =
    { p | losses = p.losses + 1, latest = matchIndex, met = Set.insert against p.met }


reduceEvents : List Event -> ( Dict Id PersonSummary, Dict Id (Dict Id (Maybe Id)) )
reduceEvents events =
    let
        reducer :
            Event
            -> { idCounter : Int, matchCounter : MatchIndex, dict : Dict Id PersonSummary, grid : Dict Id (Dict Id (Maybe Id)) }
            -> { idCounter : Int, matchCounter : MatchIndex, dict : Dict Id PersonSummary, grid : Dict Id (Dict Id (Maybe Id)) }
        reducer =
            \e acc ->
                case e of
                    PersonAdded { name } ->
                        let
                            id =
                                acc.idCounter
                        in
                        { acc
                            | idCounter = acc.idCounter + 1
                            , dict = Dict.insert id (newPersonSummary id name) acc.dict
                            , grid = Dict.insert id Dict.empty (Dict.map (\_ v -> Dict.insert id Nothing v) acc.grid)
                        }

                    PersonDisabled { id } ->
                        -- TODO remove from grid and all matches
                        -- TODO two different events, one for removing entirely, and one for marking as not active anymore
                        { acc | dict = Dict.update id (Maybe.map (\p -> { p | disabled = True })) acc.dict }

                    PersonEnabled { id } ->
                        { acc | dict = Dict.update id (Maybe.map (\p -> { p | disabled = False })) acc.dict }

                    ResultRegistered { winner, loser } ->
                        let
                            matchIndex =
                                acc.matchCounter + 1

                            updatedWithWinner =
                                case Maybe.map (addWin matchIndex loser) (Dict.get winner acc.dict) of
                                    Nothing ->
                                        acc.dict

                                    Just updatedPerson ->
                                        Dict.insert updatedPerson.id updatedPerson acc.dict

                            updatedWithLoser =
                                case Maybe.map (addLoss matchIndex winner) (Dict.get loser acc.dict) of
                                    Nothing ->
                                        updatedWithWinner

                                    Just updatedPerson ->
                                        Dict.insert updatedPerson.id updatedPerson updatedWithWinner

                            updatedGridWithWinner =
                                Dict.update winner
                                    (\m ->
                                        Maybe.map
                                            (\row ->
                                                if Dict.member loser row then
                                                    Dict.insert loser (Just winner) row

                                                else
                                                    row
                                            )
                                            m
                                    )
                                    acc.grid

                            updatedGridWithLoser =
                                Dict.update loser
                                    (\m ->
                                        Maybe.map
                                            (\row ->
                                                if Dict.member winner row then
                                                    Dict.insert winner (Just winner) row

                                                else
                                                    row
                                            )
                                            m
                                    )
                                    updatedGridWithWinner
                        in
                        { acc
                            | matchCounter = matchIndex
                            , dict = updatedWithLoser
                            , grid = updatedGridWithLoser
                        }

        reduced =
            List.foldl reducer { idCounter = 0, matchCounter = 0, dict = Dict.empty, grid = Dict.empty } events
    in
    ( reduced.dict, reduced.grid )


type alias Model =
    { startupTimestamp : Maybe Time.Posix
    , readOnly : Bool
    , winnerInput : Maybe Int
    , loserInput : Maybe Int
    , nameInput : String
    , selectedMatch : Maybe ( Id, Id )
    , selectedPerson : Maybe Id
    , events : List Event
    , publishLoading : Bool
    , joinRoomInput : String
    , errorMessage : String
    , navKey : Navigation.Key
    , online : Maybe String
    , modelIsLoading : Bool
    , joinRoomLoading : Bool
    , hasFocus : Bool
    , shouldRunPeriodicFetches : Bool
    }


emptyState : Navigation.Key -> Model
emptyState navKey =
    { startupTimestamp = Nothing
    , readOnly = False
    , winnerInput = Nothing
    , loserInput = Nothing
    , nameInput = ""
    , selectedMatch = Nothing
    , selectedPerson = Nothing
    , events = []
    , publishLoading = False
    , joinRoomInput = ""
    , errorMessage = ""
    , navKey = navKey
    , online = Nothing
    , modelIsLoading = False
    , joinRoomLoading = False
    , hasFocus = True
    , shouldRunPeriodicFetches = True
    }


periodicFetchTimeout : Int
periodicFetchTimeout =
    1000 * 60 * 60


init : JsonValue -> Url.Url -> Navigation.Key -> ( Model, Cmd Msg )
init storedState url navKey =
    let
        online =
            Url.Parser.parse Url.Parser.string url

        emptyStateWithNav =
            emptyState navKey

        initialTimeCmd =
            Task.perform GotInitialTime Time.now
    in
    case online of
        Just roomId ->
            ( { emptyStateWithNav
                | online = online
                , readOnly = True -- We are read only until we have performed the first fetch
                , modelIsLoading = True
              }
            , Cmd.batch
                [ Http.get
                    { url = "/room/" ++ roomId
                    , expect = getExpect InitialGetRoomResult
                    }
                , initialTimeCmd
                ]
            )

        Nothing ->
            ( { emptyStateWithNav | events = decodeEvents storedState }, initialTimeCmd )


type alias BackEndResponse =
    { isAdmin : Bool, data : List Event }


type alias SuccessfulPublish =
    { roomId : String }


type Msg
    = SelectMatch (Maybe ( Id, Id ))
    | SelectPerson (Maybe Id)
    | NameInputChanged String
    | JoinRoomInputChanged String
    | AddPerson
    | AddPersonKeyDown Int
    | DisablePerson Id
    | EnablePerson Id
    | RegisterResult { winner : Id, loser : Id }
    | ResetResults
    | ResetModel
    | Undo
    | JoinRoom String
    | JoinRoomKeyDown Int
    | JoinRoomResult String (Result Http.Error BackEndResponse)
    | InitialGetRoomResult (Result Http.Error BackEndResponse)
    | PeriodicGetRoom
    | PeriodicGetRoomResult (Result Http.Error BackEndResponse)
    | Publish
    | PublishResult (Result Http.Error SuccessfulPublish)
    | UpdateResult (Result Http.Error ())
    | UrlRequest Browser.UrlRequest
    | UrlChange Url.Url
    | GotFromLocalStorage JsonValue
    | VisibilityChanged Browser.Events.Visibility
    | GotInitialTime Time.Posix
    | GotCurrentTime Time.Posix


addPersonToModel : String -> Model -> Model
addPersonToModel name model =
    -- TODO do not add already existing (not removed) name
    { model | nameInput = "", events = model.events ++ [ PersonAdded { name = String.slice 0 32 <| String.concat <| String.words name } ] }


enterKeyCode : number
enterKeyCode =
    13


updateRemoteEvents : Bool -> Maybe String -> List Event -> Cmd Msg
updateRemoteEvents readonly online events =
    if readonly then
        Cmd.none

    else
        let
            json =
                encodeEvents events
        in
        case online of
            Just roomId ->
                Http.request
                    { method = "PATCH"
                    , headers = []
                    , url = "/room/" ++ roomId
                    , body = Http.jsonBody json
                    , expect = Http.expectWhatever UpdateResult
                    , timeout = Nothing
                    , tracker = Nothing
                    }

            Nothing ->
                sendToLocalStorage json


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        noChange =
            ( model, Cmd.none )
    in
    case msg of
        SelectMatch match ->
            if model.readOnly then
                noChange

            else
                ( { model | selectedMatch = match }, Cmd.none )

        SelectPerson person ->
            ( { model | selectedPerson = person }, Cmd.none )

        NameInputChanged input ->
            ( { model | nameInput = input }, Cmd.none )

        JoinRoomInputChanged input ->
            ( { model | joinRoomInput = String.slice 0 4 <| String.filter Char.isAlphaNum <| String.toLower input }, Cmd.none )

        AddPerson ->
            if model.readOnly then
                noChange

            else
                case model.nameInput of
                    "" ->
                        ( model, Cmd.none )

                    name ->
                        let
                            newModel =
                                addPersonToModel name model
                        in
                        ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

        AddPersonKeyDown keyCode ->
            if model.readOnly then
                noChange

            else if keyCode == enterKeyCode then
                case model.nameInput of
                    "" ->
                        ( model, Cmd.none )

                    name ->
                        let
                            newModel =
                                addPersonToModel name model
                        in
                        ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

            else
                ( model, Cmd.none )

        DisablePerson id ->
            if model.readOnly then
                noChange

            else
                let
                    newModel =
                        { model | selectedMatch = Nothing, events = model.events ++ [ PersonDisabled { id = id } ] }
                in
                ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

        EnablePerson id ->
            if model.readOnly then
                noChange

            else
                let
                    newModel =
                        { model | selectedMatch = Nothing, events = model.events ++ [ PersonEnabled { id = id } ] }
                in
                ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

        RegisterResult result ->
            if model.readOnly then
                noChange

            else if result.winner == result.loser then
                ( model, Cmd.none )

            else
                let
                    newModel =
                        { model | events = model.events ++ [ ResultRegistered result ], selectedMatch = Nothing }
                in
                ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

        ResetResults ->
            let
                isRegisterEvent e =
                    case e of
                        ResultRegistered _ ->
                            False

                        _ ->
                            True
            in
            -- TODO remove all inactive persons as well
            if model.readOnly then
                noChange

            else
                let
                    newModel =
                        { model | events = List.filter isRegisterEvent model.events }
                in
                ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

        ResetModel ->
            if model.readOnly then
                noChange

            else
                let
                    emptyStateWithNavKey =
                        emptyState model.navKey
                in
                ( { emptyStateWithNavKey | online = model.online }, updateRemoteEvents model.readOnly model.online [] )

        Undo ->
            if model.readOnly then
                noChange

            else
                let
                    newModel =
                        { model | events = List.take (List.length model.events - 1) model.events }
                in
                ( newModel, updateRemoteEvents model.readOnly model.online newModel.events )

        JoinRoom roomId ->
            ( { model | joinRoomLoading = True }
            , Http.get
                { url = "/room/" ++ roomId
                , expect = getExpect (JoinRoomResult roomId)
                }
            )

        JoinRoomKeyDown keyCode ->
            if keyCode == enterKeyCode then
                update (JoinRoom model.joinRoomInput) model

            else
                ( model, Cmd.none )

        JoinRoomResult roomId result ->
            case result of
                Ok ok ->
                    ( { model | online = Just roomId, joinRoomLoading = False, events = ok.data, readOnly = not ok.isAdmin, joinRoomInput = "", errorMessage = "" }, Navigation.pushUrl model.navKey roomId )

                Err err ->
                    ( { model | joinRoomLoading = False, errorMessage = httpErrorToString err }, Cmd.none )

        PeriodicGetRoom ->
            case model.online of
                Nothing ->
                    ( model, Cmd.none )

                Just roomId ->
                    ( model
                    , Http.get
                        { url = "/room/" ++ roomId
                        , expect = getExpect PeriodicGetRoomResult
                        }
                    )

        PeriodicGetRoomResult result ->
            case result of
                Ok ok ->
                    ( { model | modelIsLoading = False, events = ok.data, readOnly = not ok.isAdmin }, Cmd.none )

                Err err ->
                    ( { model | modelIsLoading = False, errorMessage = httpErrorToString err }, Cmd.none )

        InitialGetRoomResult result ->
            case result of
                Ok ok ->
                    ( { model | modelIsLoading = False, events = ok.data, readOnly = not ok.isAdmin, errorMessage = "" }, Cmd.none )

                Err err ->
                    ( { model | modelIsLoading = False, errorMessage = httpErrorToString err }, Cmd.none )

        Publish ->
            ( { model | publishLoading = True }
            , Http.post
                { url = "/room"
                , body = Http.jsonBody (encodeEvents model.events)
                , expect = publishExpect
                }
            )

        PublishResult result ->
            case result of
                Ok ok ->
                    ( { model | publishLoading = False, online = Just ok.roomId, errorMessage = "" }, Navigation.pushUrl model.navKey ok.roomId )

                Err err ->
                    ( { model | publishLoading = False, errorMessage = httpErrorToString err }, Cmd.none )

        UpdateResult result ->
            case result of
                Ok () ->
                    ( { model | errorMessage = "" }, Cmd.none )

                Err err ->
                    ( { model | errorMessage = httpErrorToString err }, Cmd.none )

        UrlRequest request ->
            -- We do not have any links on the page
            case request of
                Browser.Internal _ ->
                    ( model, Cmd.none )

                Browser.External _ ->
                    ( model, Cmd.none )

        UrlChange url ->
            let
                online =
                    Url.Parser.parse Url.Parser.string url

                emptyStateWithNav =
                    emptyState model.navKey
            in
            case online of
                Just roomId ->
                    ( { emptyStateWithNav
                        | online = online
                        , readOnly = True
                        , modelIsLoading = True
                      }
                    , Http.get
                        { url = "/room/" ++ roomId
                        , expect = getExpect InitialGetRoomResult
                        }
                    )

                Nothing ->
                    ( emptyStateWithNav
                    , requestFromLocalStorage ()
                    )

        GotFromLocalStorage storedState ->
            case model.online of
                Just _ ->
                    ( model, Cmd.none )

                Nothing ->
                    ( { model | events = decodeEvents storedState }, Cmd.none )

        VisibilityChanged vis ->
            case vis of
                Browser.Events.Visible ->
                    ( { model | hasFocus = True }, Cmd.none )

                Browser.Events.Hidden ->
                    ( { model | hasFocus = False }, Cmd.none )

        GotInitialTime time ->
            ( { model | startupTimestamp = Just time }, Cmd.none )

        GotCurrentTime currentTime ->
            case model.startupTimestamp of
                Nothing ->
                    ( model, Cmd.none )

                Just startupTime ->
                    if (Time.posixToMillis startupTime + periodicFetchTimeout) < Time.posixToMillis currentTime then
                        ( { model | shouldRunPeriodicFetches = False, errorMessage = "You have been idle for too long. Refresh the page to get updates from the current room again." }, Cmd.none )

                    else
                        ( model, Cmd.none )


httpErrorToString : Http.Error -> String
httpErrorToString err =
    case err of
        Http.Timeout ->
            "The request took too long. The service might be unavailable or your internet connection is too slow."

        Http.BadUrl url ->
            "Bad url: " ++ url

        Http.NetworkError ->
            "Network error. Did you lose your internet connection?"

        Http.BadStatus status ->
            if status == 404 then
                "Not found."

            else
                "Bad status: " ++ String.fromInt status

        Http.BadBody s ->
            "Bad request body: " ++ s


backEndResponseDecoder : Decode.Decoder BackEndResponse
backEndResponseDecoder =
    Decode.map2 (\isAdmin -> \data -> { isAdmin = isAdmin, data = data }) (Decode.field "isAdmin" Decode.bool) (Decode.field "data" eventsDecoder)


getExpect : (Result Http.Error BackEndResponse -> Msg) -> Http.Expect Msg
getExpect msg =
    Http.expectStringResponse msg <|
        \response ->
            case response of
                Http.BadUrl_ url ->
                    Err (Http.BadUrl url)

                Http.Timeout_ ->
                    Err Http.Timeout

                Http.NetworkError_ ->
                    Err Http.NetworkError

                Http.BadStatus_ metadata _ ->
                    Err (Http.BadStatus metadata.statusCode)

                Http.GoodStatus_ _ body ->
                    case Decode.decodeString backEndResponseDecoder body of
                        Ok ok ->
                            Ok ok

                        Err err ->
                            Err (Http.BadBody (Decode.errorToString err))


publishExpect : Http.Expect Msg
publishExpect =
    Http.expectStringResponse PublishResult <|
        \response ->
            case response of
                Http.BadUrl_ url ->
                    Err (Http.BadUrl url)

                Http.Timeout_ ->
                    Err Http.Timeout

                Http.NetworkError_ ->
                    Err Http.NetworkError

                Http.BadStatus_ metadata _ ->
                    Err (Http.BadStatus metadata.statusCode)

                Http.GoodStatus_ metadata _ ->
                    case Dict.get "location" metadata.headers of
                        Nothing ->
                            Err (Http.BadBody "Missing location header after publish, it should contain the newly created room id.")

                        Just location ->
                            Ok { roomId = location }


personName : Dict Id PersonSummary -> Id -> String
personName dict id =
    Dict.get id dict |> Maybe.map .name |> Maybe.withDefault "-"


personResult : Dict Id PersonSummary -> Id -> String
personResult dict id =
    Dict.get id dict |> Maybe.map (\p -> String.fromInt p.wins ++ " - " ++ String.fromInt p.losses) |> Maybe.withDefault ""


viewGrid : Dict Id PersonSummary -> Dict Id (Dict Id (Maybe Id)) -> Maybe ( Id, Id ) -> Maybe Id -> List (Html Msg)
viewGrid dict grid selectedMatch selectedPerson =
    let
        gridListAll =
            Dict.toList grid
                |> List.sortBy first
                |> List.map
                    (\( id, list ) ->
                        ( id
                        , Dict.toList list
                            |> List.sortBy (\v -> -(first v))
                        )
                    )

        gridList =
            List.take (List.length gridListAll - 1) gridListAll

        firstRow =
            List.head gridList

        isDisabledPerson id =
            Maybe.withDefault False <| Maybe.map .disabled <| Dict.get id dict

        viewHeader =
            \( _, list ) ->
                div [ class "grid-row" ]
                    (div [ class "corner" ] []
                        :: (list
                                |> List.map
                                    (\( id, _ ) ->
                                        button
                                            [ classList
                                                [ ( "top-header", True )
                                                , ( "button-disabled-person-background", isDisabledPerson id )
                                                , ( "button-selected-person-border", id == gridSelectedPerson )
                                                ]
                                            , onClick
                                                (SelectPerson
                                                    (if id == gridSelectedPerson then
                                                        Nothing

                                                     else
                                                        Just id
                                                    )
                                                )
                                            ]
                                            [ div [] [ text (personName dict id) ], div [] [ text (personResult dict id) ] ]
                                    )
                           )
                    )

        maybeHeader =
            Maybe.map viewHeader firstRow

        headerRow =
            Maybe.withDefault (div [] []) maybeHeader

        gridSelectedMatch =
            Maybe.withDefault ( -1, -1 ) selectedMatch

        gridSelectedPerson =
            Maybe.withDefault -1 selectedPerson

        isWinner : Maybe Id -> Id -> Bool
        isWinner result id =
            Maybe.withDefault False <| Maybe.map (\resultId -> resultId == id) result

        isLoser : Maybe Id -> Id -> Bool
        isLoser result id =
            Maybe.withDefault False <| Maybe.map (\resultId -> resultId /= id) result
    in
    headerRow
        :: (gridList
                |> List.map
                    (\( id1, row ) ->
                        div [ class "grid-row" ]
                            (button
                                [ classList
                                    [ ( "left-header", True )
                                    , ( "button-disabled-person-background", isDisabledPerson id1 )
                                    , ( "button-selected-person-border", id1 == gridSelectedPerson )
                                    ]
                                , onClick <|
                                    SelectPerson <|
                                        if id1 == gridSelectedPerson then
                                            Nothing

                                        else
                                            Just id1
                                ]
                                [ div [] [ text (personName dict id1) ], div [] [ text (personResult dict id1) ] ]
                                :: (row
                                        |> List.map
                                            (\( id2, result ) ->
                                                button
                                                    [ classList
                                                        [ ( "grid-button", True )
                                                        , ( "grid-button-finished", isJust result )
                                                        , ( "button-selected-person-border", id1 == gridSelectedPerson || id2 == gridSelectedPerson )
                                                        , ( "button-disabled-person-background", (isDisabledPerson id1 || isDisabledPerson id2) && isNothing result )
                                                        , ( "button-selected-match-border", ( id1, id2 ) == gridSelectedMatch || ( id2, id1 ) == gridSelectedMatch )
                                                        ]
                                                    , onClick <|
                                                        SelectMatch <|
                                                            if isJust result then
                                                                selectedMatch

                                                            else if ( id1, id2 ) == gridSelectedMatch || ( id2, id1 ) == gridSelectedMatch then
                                                                Nothing

                                                            else
                                                                Just ( id1, id2 )
                                                    ]
                                                    [ div
                                                        [ classList
                                                            [ ( "winner", isWinner result id1 )
                                                            , ( "loser", isLoser result id1 )
                                                            ]
                                                        ]
                                                        [ text (personName dict id1) ]
                                                    , text " vs "
                                                    , div
                                                        [ classList
                                                            [ ( "winner", isWinner result id2 )
                                                            , ( "loser", isLoser result id2 )
                                                            ]
                                                        ]
                                                        [ text (personName dict id2) ]
                                                    ]
                                            )
                                   )
                            )
                    )
           )


nextUp : Dict Id PersonSummary -> Maybe ( Id, Id ) -> Maybe ( Id, Id )
nextUp dict extraMatch =
    let
        sortScore p =
            p.latest * 10000000 + p.wins * 1000 + p.id

        sortedWithExtraMatchAdded =
            Dict.values dict
                |> List.filter (\p -> not p.disabled)
                |> List.map
                    (\p ->
                        extraMatch
                            |> Maybe.map
                                (\( id1, id2 ) ->
                                    { p
                                        | latest =
                                            if p.id == id1 || p.id == id2 then
                                                10000

                                            else
                                                p.latest
                                        , met =
                                            if p.id == id1 || p.id == id2 then
                                                Set.insert id2 (Set.insert id1 p.met)

                                            else
                                                p.met
                                    }
                                )
                            |> Maybe.withDefault p
                    )
                |> List.sortBy sortScore

        findFirstUnmet : Id -> PersonSummary -> Maybe Id -> Maybe Id
        findFirstUnmet id p match =
            case match of
                Just _ ->
                    match

                Nothing ->
                    if id == p.id || Set.member id p.met then
                        Nothing

                    else
                        Just p.id

        findFirstMatch : List PersonSummary -> PersonSummary -> Maybe ( Id, Id ) -> Maybe ( Id, Id )
        findFirstMatch allPersons p match =
            case match of
                Just _ ->
                    match

                Nothing ->
                    List.foldl (findFirstUnmet p.id) Nothing allPersons
                        |> Maybe.map (\firstUnmet -> ( p.id, firstUnmet ))

        nextMatch =
            List.foldl (findFirstMatch sortedWithExtraMatchAdded) Nothing sortedWithExtraMatchAdded
    in
    nextMatch


isJust : Maybe a -> Bool
isJust m =
    Maybe.withDefault False <| Maybe.map (\_ -> True) m


isNothing : Maybe a -> Bool
isNothing m =
    Maybe.withDefault True <| Maybe.map (\_ -> False) m


view : Model -> Browser.Document Msg
view model =
    let
        ( dict, grid ) =
            reduceEvents model.events

        nextMatch =
            case model.selectedMatch of
                Just m ->
                    Just m

                Nothing ->
                    nextUp dict Nothing

        nextNextMatch =
            nextUp dict nextMatch
    in
    { title = "RR-matcher"
    , body =
        [ div [ class "main-content" ]
            [ div [] (viewGrid dict grid nextMatch model.selectedPerson)
            , br [] []
            , div [] [ viewCurrentMatch model.readOnly dict nextMatch nextNextMatch (isNothing model.selectedMatch) ]
            , br [] []
            , viewTopList dict
            , br [] []
            , div [] [ onlineOfflineView model ]
            , br [] []
            , addPersonView model
            , br [] []
            , toggleDisablePersonsView model dict
            , br [] []
            , viewControls model
            , br [] []
            , viewJoinRoomButton model
            , br [] []
            , viewError model
            ]
        ]
    }


addPersonView : Model -> Html Msg
addPersonView model =
    if model.readOnly then
        text ""

    else
        div []
            [ input
                [ value model.nameInput
                , onInput NameInputChanged
                , on "keydown" (Decode.map AddPersonKeyDown keyCode)
                ]
                []
            , button [ onClick AddPerson ] [ text "Add person" ]
            ]


onlineOfflineView : Model -> Html msg
onlineOfflineView model =
    case model.online of
        Just roomId ->
            text ("Online: room " ++ roomId)

        Nothing ->
            text "Offline"


viewTopList : Dict Id PersonSummary -> Html Msg
viewTopList dict =
    let
        sortedAndIndexed : List ( Int, PersonSummary )
        sortedAndIndexed =
            List.indexedMap Tuple.pair <| List.sortBy (\p -> -p.wins) <| Dict.values dict

        top : List ( Int, PersonSummary )
        top =
            .acc <|
                List.foldl
                    (\( i, p ) prev ->
                        let
                            ii =
                                if p.wins == prev.wins then
                                    prev.i

                                else
                                    i
                        in
                        { i = ii, wins = p.wins, acc = prev.acc ++ [ ( ii, p ) ] }
                    )
                    { i = -1, wins = -1, acc = [] }
                <|
                    sortedAndIndexed
    in
    div []
        (List.map
            (\( i, p ) ->
                div [ class ("top-" ++ String.fromInt (i + 1)) ]
                    [ text (String.fromInt (i + 1) ++ ". " ++ p.name ++ "  (" ++ String.fromInt p.wins ++ " - " ++ String.fromInt p.losses ++ ")")
                    ]
            )
            top
        )


viewCurrentMatch : Bool -> Dict Id PersonSummary -> Maybe ( Id, Id ) -> Maybe ( Id, Id ) -> Bool -> Html Msg
viewCurrentMatch readOnly dict next nextNext isInOrder =
    case next of
        Nothing ->
            div [] []

        Just ( a, b ) ->
            div []
                [ div [ class "current-match" ]
                    [ div []
                        [ button [ class "vs-button", onClick <| RegisterResult { winner = a, loser = b } ]
                            [ text (Dict.get a dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                        , text " vs "
                        , button [ class "vs-button", onClick <| RegisterResult { winner = b, loser = a } ]
                            [ text (Dict.get b dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                        ]
                    ]
                , br [] []
                , case nextNext of
                    Just ( id1, id2 ) ->
                        div [ class "next-match" ]
                            [ div []
                                [ text "Then "
                                , button [ class "vs-button-next", onClick <| RegisterResult { winner = id1, loser = id2 } ]
                                    [ text (Dict.get id1 dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                                , text " vs "
                                , button [ class "vs-button-next", onClick <| RegisterResult { winner = id2, loser = id1 } ]
                                    [ text (Dict.get id2 dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                                ]
                            ]

                    Nothing ->
                        br [] []
                , br [] []
                , if readOnly then
                    text ""

                  else
                    button
                        [ classList [ ( "button-selected-match-border", isInOrder ) ]
                        , onClick <| SelectMatch Nothing
                        ]
                        [ text "Next in order" ]
                ]


toggleDisablePersonsView : Model -> Dict Id PersonSummary -> Html Msg
toggleDisablePersonsView model dict =
    if model.readOnly then
        text ""

    else
        div []
            (Dict.values dict
                |> List.map
                    (\p ->
                        div []
                            [ if p.disabled then
                                button [ class "toggle-enable-button", onClick <| EnablePerson p.id ] [ text "Enable " ]

                              else
                                button [ class "toggle-disable-button", onClick <| DisablePerson p.id ] [ text "Disable" ]
                            , text ("  " ++ p.name)
                            ]
                    )
            )


viewControls : Model -> Html Msg
viewControls model =
    if model.readOnly then
        text ""

    else
        div [ class "controls" ]
            [ button [ onClick Undo ] [ text "Undo" ]
            , button [ onClick ResetResults ] [ text "Reset matches" ]
            , button [ onClick Publish, disabled model.publishLoading ] [ text "Publish room" ]
            , button [ onClick ResetModel ] [ text "Reset all" ]
            ]


viewJoinRoomButton : Model -> Html Msg
viewJoinRoomButton model =
    div []
        [ input
            [ value model.joinRoomInput
            , onInput JoinRoomInputChanged
            , on "keydown" (Decode.map JoinRoomKeyDown keyCode)
            ]
            []
        , button
            [ onClick <| JoinRoom model.joinRoomInput
            , disabled model.joinRoomLoading
            ]
            [ text "Join room" ]
        ]


viewError : Model -> Html Msg
viewError model =
    div [ class "error-message" ] [ text model.errorMessage ]
