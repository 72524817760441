import { Elm } from "./Main.elm";

const localStorageKey = "rrmatcher-state"

let app = Elm.Main.init({
  node: document.getElementById("root"),
  flags:
    localStorage[localStorageKey] === undefined
      ? undefined
      : JSON.parse(localStorage[localStorageKey]),
});

app.ports.sendToLocalStorage.subscribe((elmState: string) => {
  localStorage[localStorageKey] = JSON.stringify(elmState);
});

app.ports.requestFromLocalStorage.subscribe(() => {
  app.ports.receiveFromLocalStorage.send(
    localStorage[localStorageKey] === undefined
      ? undefined
      : JSON.parse(localStorage[localStorageKey]),
  );
});
